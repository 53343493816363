<template>
	<v-app>
		<v-navigation-drawer
			:width="400"
			app
			v-model="drawer"
			style="background-color: #ccc"
			clipped
		>
			<v-container>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="title grey--text text--darken-2">
							プロジェクト名称
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<div style="margin: 10px">
					<v-btn style="margin-right: 10px" @click.stop="showMap"
						>マップ表示</v-btn
					>
					<v-btn @click.stop="showList">リスト表示</v-btn>
				</div>


				<v-simple-table>
					<template>
						<thead>
							<tr>
								<th class="border-left" style="color: #333">ID</th>
								<th class="border-left" style="color: #333">エリア</th>
								<th class="border-left" style="color: #333">設置場所 / 水位</th>
								<th class="border-right" style="color: #333"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="item in summary"
								:key="item.id"
								@click="clickRow(item)"
								style="cursor: pointer"
								v-bind:class="[
									item.id == $route.params.id ? 'row-selected' : 'none',
								]"
							>
								<td class="border-left">{{ item.id }}</td>
								<td class="border-left">
									<span style="font-size: 12px">{{ item.district }}</span>
								</td>
								<td class="border-left">
									<div
										style="padding-top: 2px; font-size: 10px; color: #222222"
									>
										最終データ取得: 10/26 22:13
									</div>
									<div style="padding-bottom: 2px">
										<span style="font-size: 13px; font-weight: bold">
											{{ item.name }}
										</span>
										<span
											style="
												margin-left: 2px;
												font-weight: bold;
												font-size: 14px;
											"
										>
											{{ item.waterlevel }}</span
										>
										{{ item.unit }}
									</div>
								</td>
								<td class="border-right">
									<span
										:style="[
											{
												display: 'inline-block',
												margin: 'auto',
												'line-height': '12px',
												width: '12px',
												height: '12px',
												background: alertColor(item),
											},
										]"
									></span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<div
					style="
						display: block;
						width: 100%;
						text-align: right;
						margin-top: 15px;
					"
				>
					<div>
						<div
							:style="[
								{
									display: 'inline-block',
									width: '14px',
									height: '14px',
									background: $store.state.iconColors[4],
								},
							]"
						></div>
						<span style="margin-left: 5px; margin-right: 10px"> 氾濫発生 </span>
					</div>
					<div>
						<div
							:style="[
								{
									display: 'inline-block',
									width: '14px',
									height: '14px',
									background: $store.state.iconColors[3],
								},
							]"
						></div>
						<span style="margin-left: 5px; margin-right: 10px"> 氾濫危険 </span>
					</div>
					<div>
						<div
							:style="[
								{
									display: 'inline-block',
									width: '14px',
									height: '14px',
									background: $store.state.iconColors[2],
								},
							]"
						></div>
						<span style="margin-left: 5px; margin-right: 10px"> 氾濫警戒 </span>
					</div>
					<div>
						<div
							:style="[
								{
									display: 'inline-block',
									width: '14px',
									height: '14px',
									background: $store.state.iconColors[1],
								},
							]"
						></div>
						<span style="margin-left: 5px; margin-right: 10px"> 氾濫注意 </span>
					</div>
					<div>
						<div
							:style="[
								{
									display: 'inline-block',
									width: '14px',
									height: '14px',
									background: $store.state.iconColors[0],
								},
							]"
						></div>
						<span style="margin-left: 5px; margin-right: 10px"> 問題なし </span>
					</div>
				</div>
				<v-spacer style="height: 100px"></v-spacer>
			</v-container>
		</v-navigation-drawer>

		<v-app-bar color="grey darken-3" dark app clipped-left dense flat>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title style="cursor: pointer" @click="$router.push('/')"
				>CityView</v-toolbar-title
			>

			<v-spacer></v-spacer>

			<v-toolbar-items>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-avatar v-on="on" size="36" style="margin-top: 5px;">
							<img :src="login.image" :alt="login.name" />
						</v-avatar>
					</template>
					<v-list>
						<v-subheader>マイアカウント</v-subheader>
						<v-list-item link @click.stop="$router.push('/mypage')">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>マイページ</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-show="login.role == 'admin'"
							link
							@click.stop="$router.push('/admin/home')"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account-supervisor" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>管理画面</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click.stop="logoutDialog = true">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-logout" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>ログアウト</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>
		<v-main>
			<router-view :key="$route.fullPath" />
		</v-main>
		<v-footer color="grey darken-3" dark app>
			<v-spacer></v-spacer>
			©2020
			<a
				style="margin-left: 4px; color: #ffffff"
				href="https://www.vegetalia.co.jp/"
			>
				vegetalia</a
			>, inc.
		</v-footer>

		<!-- modal -->
		<div class="text-center">
			<v-dialog v-model="logoutDialog" width="500">
				<v-card>
					<v-card-title class="headline"> マイアカウント </v-card-title>
					<v-card-text>ログアウトしますか？</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="logoutDialog = false">
							いいえ
						</v-btn>
						<v-btn color="green darken-1" text @click="logout"> はい </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<!-- modal END-->
	</v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
	computed: {
		alertColor() {
			return function (item) {
				return this.$store.getters.getAlertColor(item);
			};
		},
		...mapState(["summary"]),
	},
	data() {
		return {
			drawer: null,
			logoutDialog: false,
			pwchangeDialog: false,
			login: {
				name: "unknown",
				role: "admin",
				image: "/img/noimage.png"
			},
		};
	},
	mounted() {
		this.scrollTop();
	},
	methods: {
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		clickRow: function(it) {
			this.$router
				.push({ name: "Place", params: { id: it.id, item: it } })
				.catch(() => {});
		},
		showMap: function() {
			this.$router
				.push({ name: "Home", params: {} })
				.catch(() => {});
		},
		showList: function() {
			this.$router
				.push({ name: "List", params: {} })
				.catch(() => {});
		},
		logout: function() {
			this.logoutDialog = false;
		}
	},
};
</script>
<style scoped>
.border-left {
	border-left: 2px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}
.border-left-right {
	border-left: 2px solid #dddddd;
	border-right: 2px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}
.row-selected {
	background-color: #eee;
}
</style>